import axios from '@/common/axios'
import qs from 'qs'

export function addMenu (data) {
  return axios({
    method: 'post',
    url: '/menu/add',
    data: qs.stringify(data)
  })
}

export function deleteMenu (id) {
  return axios({
    method: 'delete',
    url: '/menu/delete/' + id
  })
}

export function updateMenu (data) {
  return axios({
    method: 'put',
    url: '/menu/update',
    data: qs.stringify(data)
  })
}

export function selectMenuInfo (id) {
  return axios({
    method: 'get',
    url: '/menu/info/' + id
  })
}

export function selectMenuTree () {
  return axios({
    method: 'get',
    url: '/menu/tree'
  })
}

export function selectMenuTreeWithPermission () {
  return axios({
    method: 'get',
    url: '/menu/treeWithPermission'
  })
}
